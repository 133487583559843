<template>
  <btn
    component-name="btn-arrow"
    :title="icon.title"
    :disabled="$attrs.disabled ? $attrs.disabled : undefined"
    class="overflow-hidden flex justify-center items-center w-13 h-13 leading-none bg-transparent text-skin-base border border-current hover:border-transparent rounded-full hover:bg-skin-inverse hover:text-skin-inverse transition-[opacity,border,background,color] duration-300 ease-in-out"
    :class="{
      'opacity-50': $attrs.disabled,
    }"
    @mouseenter="
      $device.isDesktop && !$attrs.disabled ? tpl_hoverIcon.animate() : () => {}
    "
  >
    <div
      class="block relative top-0 left-0 transition-transform ease-out duration-500 group-hover/btn:scale-[120%]"
    >
      <hover-icon
        ref="tpl_hoverIcon"
        :icon="icon.direction"
        :size="$attrs.size ? $attrs.size : 'regular'"
      />
    </div>
  </btn>
</template>

<script setup>
const props = defineProps({
  direction: {
    type: String,
    required: true,
    validator: function (value) {
      return ["next", "prev"].indexOf(value) !== -1;
    },
  },
});
const tpl_hoverIcon = templateRef("tpl_hoverIcon");
const nuxtApp = useNuxtApp();

const icon = computed(() => {
  let icn = {};
  switch (props.direction) {
    case "prev":
      icn.direction = "arrow-long-left";
      icn.title = nuxtApp.$t.labels.prev;
      break;

    case "next":
      icn.direction = "arrow-long-right";
      icn.title = nuxtApp.$t.labels.next;
      break;
  }

  return icn;
});
</script>
